import React, { useContext } from "react";
import styled from "styled-components";
import { rgba } from "polished";
import { Link } from "gatsby";
import { Container, Row, Col } from "react-bootstrap";
import { Title, Button, Section, Box } from "../../components/Core";
// import ReactRotatingText from "react-rotating-text";
import { device } from "../../utils";
import imgL1FeatureCurve from "../../assets/image/l1-curve-feature2.svg";
import PreviewCompatibleImage from "../../components/PreviewCompatibleImage";
import svgHeroShape from "../../assets/image/hero-shape-svg.svg";
import { langKeyDefault } from "../../../buildVariables";
import GlobalContext from "../../context/GlobalContext";
import Typewriter from "typewriter-effect";

const SportImage = styled.div`
  display: none;

  @media ${device.lg} {
    display: block;
    background: linear-gradient(
        to bottom right,
        rgba(0, 0, 0, 0.8),
        rgba(255, 255, 255, 0) 30%
      ),
      url(${(props) => props.image.childImageSharp.fluid.src}) no-repeat;
    background-size: cover;
    background-position: center;
    padding: 0;
    height: 90vh;
    min-height: 40vw;
    width: 40vw;
    border-bottom-right-radius: 100%;
    position: absolute;
    left: 0;
    top: 0;
  }
`;

const StyledCol = styled(Col)`
  margin-bottom: auto;
  margin-top: -35px;

  @media ${device.lg} {
    max-height: calc(100vh - 30px);
  }
  @media ${device.xl} {
    max-height: calc(100vh - 70px);
  }
`;

const ImgStyled = styled.div`
  filter: drop-shadow(0 52px 54px rgb(128 144 187 / 58%));
  margin-bottom: 80px;
`;

const ShapeTopLeft = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
  @media ${device.lg} {
    display: none;
  }
`;

const ShapeTopLeftNoImage = styled.div`
  position: absolute;
  left: 0;
  top: 0;
  transform: translate(-50%, -50%);
`;

const ULStyled = styled.ul`
  list-style: none;
  margin: 0;
  padding-top: 0;
  padding-left: 0;

  li {
    &:nth-child(odd) {
      @media ${device.sm} {
        margin-right: 40px;
      }
    }
    color: #19191b;
    font-size: 21px;
    font-weight: 500;
    letter-spacing: -0.66px;
    line-height: 30px;
    display: flex;
    margin-bottom: 15px;

    &:before {
      content: "\f00c";
      font-family: "Font Awesome 5 Free";
      font-weight: 900;
      display: inline-block;
      font-size: 11px;
      width: 30px;
      min-width: 30px;
      height: 30px;
      background-color: ${({ theme }) => rgba(theme.colors.secondary, 0.1)};
      display: flex;
      align-items: center;
      justify-content: center;
      border-radius: 500px;
      color: ${({ theme }) => theme.colors.secondary};
      position: relative;
      margin-right: 13px;
    }
  }
`;

const ShapeBottomRight = styled(Box)`
  position: absolute;
  z-index: 2;
  bottom: -10px;
  left: 0;
  width: 100%;
  img {
    min-width: 100%;
  }
`;

const TournamentText = styled.span`
  display: block;
  @media ${device.sm} {
    display: inline;
  }
`;

const StyledButton = styled(Button)`
  font-size: 13px;
  font-weight: 700;
  border-radius: 15px;
  padding: 4px 12px;
  min-width: 0;
`;

const StyledCTAButton = styled(Button)`
  padding: 12px 18px;
  white-space: normal;
  @media ${device.md} {
    padding: 0.85rem 1.75rem;
  }
`;

const SportsName = styled.span`
  color: #f04037;
`;

const Hero = (props) => {
  const gContext = useContext(GlobalContext);

  const openVideoModal = (e) => {
    e.preventDefault();
    gContext.toggleVideoModal();
  };

  return (
    <>
      <Section
        className="position-relative overflow-hidden"
        pb="0px !important"
        pt={[
          "50px !important",
          "80px !important",
          "80px !important",
          "120px !important",
        ]}
      >
        {props.image ? (
          <SportImage image={props.image} />
        ) : (
          <ShapeTopLeftNoImage>
            <img src={svgHeroShape} alt="" className="img-fluid" />
          </ShapeTopLeftNoImage>
        )}
        <ShapeTopLeft>
          <img src={svgHeroShape} alt="" className="img-fluid" />
        </ShapeTopLeft>
        <ShapeBottomRight>
          <img src={imgL1FeatureCurve} alt="" className="img-fluid" />
        </ShapeBottomRight>
        <Container>
          <Row className="align-items-center position-relative justify-content-lg-center">
            <StyledCol
              lg="5"
              className="position-static d-none d-lg-block"
              data-aos="fade-right"
              data-aos-duration="750"
              data-aos-delay="500"
              data-aos-once="true"
            >
              <ImgStyled>
                <PreviewCompatibleImage
                  imageInfo={{
                    image: props.frontImage,
                  }}
                />
              </ImgStyled>
            </StyledCol>
            <Col lg="7" style={{ zIndex: 10 }}>
              <div
                data-aos="fade-left"
                data-aos-duration="750"
                data-aos-delay="500"
                data-aos-once="true"
              >
                <Box pt={["50px", null, null, 0]} pb={["50px", "50px", "80px"]}>
                  {!props.sportPage ? (
                    <>
                      <Link
                        to={
                          "/" +
                          (props.langKey === langKeyDefault
                            ? ""
                            : props.langKey + "/") +
                          "sports"
                        }
                      >
                        <StyledButton
                          size="small"
                          mb="10px"
                          mr="10px"
                          bg="#f04037"
                        >
                          <i className="far fa-futbol mr-2" />{" "}
                          {props.sportLabel}
                        </StyledButton>
                      </Link>
                      <Link
                        to={
                          "/" +
                          (props.langKey === langKeyDefault
                            ? ""
                            : props.langKey + "/") +
                          "sports/esports/"
                        }
                      >
                        <StyledButton mb="10px" bg="#f04037">
                          <i className="fas fa-gamepad mr-2" />{" "}
                          {props.esportLabel}
                        </StyledButton>
                      </Link>{" "}
                    </>
                  ) : null}
                  <Title>
                    {props.heading}
                    <br />
                    {props.reverse
                      ? props.sportPage &&
                        props.online &&
                        props.langKey === "fr"
                        ? "tournoi "
                        : props.heading2
                      : null}
                    {props.sports.split(", ").length > 1 ? (
                      <Typewriter
                        options={{
                          strings: props.sports.split(", "),
                          autoStart: true,
                          loop: true,
                          cursor: "",
                        }}
                      />
                    ) : (
                      <SportsName>
                        {props.langKey === "de"
                          ? props.sports
                          : props.sports_name
                          ? props.sports_name
                          : props.sports.charAt(0).toLowerCase() +
                            props.sports.slice(1)}
                      </SportsName>
                    )}

                    {!props.reverse && (
                      <TournamentText>{props.heading2}</TournamentText>
                    )}
                    <span style={{ display: "block" }}>{props.heading3}</span>
                  </Title>
                  <Box mb={4}>
                    <ULStyled>
                      <li>{props.subheading}</li>
                      <li>{props.subheading2}</li>
                      <li>{props.subheading3}</li>
                    </ULStyled>
                  </Box>
                  <div className="d-flex flex-column align-items-start pt-2">
                    <a
                      href={
                        "https://" +
                        props.url +
                        "/manage/new" +
                        (props.affiliate ? "/?ref=" + props.affiliate : "")
                      }
                      target="_blank"
                      rel="noopener noreferrer"
                      id="clickNewTournament"
                    >
                      <StyledCTAButton mb={2}>
                        <i
                          className="fas fa-trophy d-none d-md-block"
                          style={{ marginRight: 10 }}
                        />
                        {props.cta}
                      </StyledCTAButton>
                    </a>
                    <a
                      href="https://youtu.be/fW6QoY0mQWg"
                      className="text-decoration-none"
                      onClick={openVideoModal}
                      id="clickWatchVideo"
                    >
                      <Box color="secondary">
                        <i className="fas fa-play-circle mr-1" />{" "}
                        {props.watchVideo}
                      </Box>
                    </a>
                  </div>
                </Box>
              </div>
            </Col>
          </Row>
        </Container>
      </Section>
    </>
  );
};

export default Hero;
